import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {
  pageLimit = 4;
  paginationData:any ={"info":"hide"};
  mconnectpaginationData:any ={"info":"hide"};
  calltelpaginationData:any ={"info":"hide"};
  voippaginationData:any ={"info":"hide"};
  offset_count = 0;
  customer_id:any;
  recordNotFound = false;
  blog_list;
  mconnectBLogs;
  calltelblogs;
  mrvoipblogs;
  ShowNext=true

  constructor(private serverService: ServerService, public router:Router,private ActivatedRoute:ActivatedRoute ) { 
 
    let tag= this.ActivatedRoute.snapshot.queryParamMap.get('tag');
    if(tag !=undefined && tag != "undefined"){
      this.ShowNext=false;
    }
    this.customer_id =localStorage.getItem('userId');
  }

  ngOnInit(): void {

    this.get_blogdata({});
    this.getMconnectBLog({});
    this.getcalltelBlogs({});
    this.getMrvoipBLog({});
  }



get_blogdata(data){

  var list_data= this.listDataInfo(data);
  let access_token: any=localStorage.getItem('access_token');
  let api_req:any = '{"operation":"blog","moduleType":"blog","api_type":"web","access_token":"'+access_token+'","element_data":{"customer_id":"'+this.customer_id+'","limit":"'+list_data.limit+'","action":"blog_lists","offset":"'+list_data.offset+'"}}';
  this.serverService.sendServer(api_req).subscribe((response: any) => {
    // console.log(response)
            if(response.status=='true'){
              this.blog_list = response.post_list;
              // console.log(this.blog_list)
              this.offset_count = list_data.offset;
              // console.log(this.offset_count)
              this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
               // console.log(this.paginationData);
                      this.recordNotFound = this.blog_list.length == 0 ? true : false;

            }else{
            }
          }, 
          (error)=>{
              // console.log(error);
          });
}


getMconnectBLog(data){

  var list_data= this.listDataInfo(data);
  let access_token: any=localStorage.getItem('access_token');
  let api_req:any = '{"operation":"blog","moduleType":"blog","api_type":"web","access_token":"'+access_token+'","element_data":{"customer_id":"'+this.customer_id+'","limit":"'+list_data.limit+'","action":"mconnectapps_blog_lists","offset":"'+list_data.offset+'"}}';
  this.serverService.sendServer(api_req).subscribe((response: any) => {
    // // console.log(response)
            if(response.status=='true'){
              this.mconnectBLogs = response.post_list;
              // // console.log(this.mconnectBLogs)
              this.offset_count = list_data.offset;
              // // console.log(this.offset_count)
              this.mconnectpaginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
               // // console.log(this.mconnectpaginationData);
                      this.recordNotFound = this.mconnectBLogs.length == 0 ? true : false;

            }else{
            }
          }, 
          (error)=>{
              // // console.log(error);
          });
}

getcalltelBlogs(data){

  var list_data= this.listDataInfo(data);
  let access_token: any=localStorage.getItem('access_token');
  let api_req:any = '{"operation":"blog","moduleType":"blog","api_type":"web","access_token":"'+access_token+'","element_data":{"customer_id":"'+this.customer_id+'","limit":"'+list_data.limit+'","action":"call4tel_blog_lists","offset":"'+list_data.offset+'"}}';
  this.serverService.sendServer(api_req).subscribe((response: any) => {
    // // console.log(response)
            if(response.status=='true'){
              this.calltelblogs = response.post_list;
              // // console.log(this.calltelblogs)
              this.offset_count = list_data.offset;
              // // console.log(this.offset_count)
              this.calltelpaginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
               // // console.log(this.calltelpaginationData);
                      this.recordNotFound = this.calltelblogs.length == 0 ? true : false;

            }else{
            }
          }, 
          (error)=>{
              // // console.log(error);
          });
}

getMrvoipBLog(data){

  var list_data= this.listDataInfo(data);
  let access_token: any=localStorage.getItem('access_token');
  let api_req:any = '{"operation":"blog","moduleType":"blog","api_type":"web","access_token":"'+access_token+'","element_data":{"customer_id":"'+this.customer_id+'","limit":"'+list_data.limit+'","action":"mrvoip_blog_lists","offset":"'+list_data.offset+'"}}';
  this.serverService.sendServer(api_req).subscribe((response: any) => {
    // // console.log(response)
            if(response.status=='true'){
              this.mrvoipblogs = response.post_list;
              // // console.log(this.mrvoipblogs)
              this.offset_count = list_data.offset;
              // // console.log(this.offset_count)
              this.voippaginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
               // // console.log(this.voippaginationData);
                      this.recordNotFound = this.mrvoipblogs.length == 0 ? true : false;

            }else{
            }
          }, 
          (error)=>{
              // // console.log(error);
          });
}

 


listDataInfo(list_data){
  // console.log(list_data)
    list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
    // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
    // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
    list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
    list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
    return list_data;
  } 
  
gotoPage(){
  localStorage.setItem('ftime_show_blog', '0');
  this.router.navigate(['/mc']);
}

}
