import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internal-kb',
  templateUrl: './internal-kb.component.html',
  styleUrls: ['./internal-kb.component.css']
})
export class InternalKbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
