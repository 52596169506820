import { Component, OnInit } from '@angular/core';
import { ServerService } from "./../services/server.service";
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { GoogleChartComponent } from 'angular-google-charts';
@Component({
  selector: 'app-enquiry-report',
  templateUrl: './enquiry-report.component.html',
  styleUrls: ['./enquiry-report.component.css']
})
export class EnquiryReportComponent implements OnInit {
 
  enquiry_list:any;
  pageLimit = 20;
  paginationData:any ={"info":"hide"};
  offset_count = 0;
  recordNotFound = false;
  clicktype:any;
  fromdate:any;
  todate:any;
  selectedDept='';
  selectedStatus='';
  enquiryTypeList:any;
  agent_options_dept:any;
  admin_id:any;
  access_token:any;
  Queue_options:any;
  Readmore = false;
  chartdata:any;
  longText = `This is long paragraph text containing several words continued. An example of implementing dynamically limit long text`;


  chartOptions = {
	  animationEnabled: true,
	  theme: "light2",
	  exportEnabled: true,
	  title: {
		text: "Out Come"
    },
    colorSet:[//colorSet Array

      "#2F4F4F",
      "#008080",
      "#2E8B57",
      "#3CB371",
      "#90EE90"                
      ],
	  data: [{
		type: "pie", //change type to column, line, area, doughnut, etc
    indexLabel: "{name}: {y}%",
    
		dataPoints: [
			{ name: "Price Too High", y: 9.1, color: "#ffc107"},
			{ name: "Revisit", y: 3.7,  color: "#fc544b" },
			{ name: "Closed", y: 36.4, color: "#7f40d4" },
			{ name: "Prospect Converted To Customer", y: 30.7 , color: "#3abaf4"},
			{ name: "Just an Enquiry", y: 20.1, color: "#54ca68" },
			{ name: "Hold", y: 20.1 , color: "#F98404"},
			{ name: "No Response", y: 20.1 , color: "#6b7070"}
    ],

	  }]
	}


  // ================================= GOOGLE CHART START =================================


  title = 'googlechart';  
  type = 'PieChart';  
  // data = [  
  //    ['Name1', 5.0],  
  //    ['Name2', 36.8],  
  //    ['Name3', 42.8],  
  //    ['Name4', 18.5],  
  //    ['Name5', 16.2]  
  // ];  
  columnNames = ['Name', 'Percentage'];  
  options = {      
    is3D:true,
    colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6']

  };  
  width = 750;  
  height = 450;


  // ================================= GOOGLE CHART ENDS =================================

  constructor(public serverService: ServerService, public router:Router) { }

  ngOnInit(): void {

    this.access_token = localStorage.getItem('access_token');
    this.admin_id = localStorage.getItem('admin_id');

    this.getEnquiryList({});
    this.getEnquiryDropdown();
    // this.GetAgentbyDepartment();
    this.graphForOutCome();

  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.Queue_options = this.serverService.dept_list;
    }, 2000);
  }

  listDataInfo(list_data){

		list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
		list_data.order_by_name = list_data.order_by_name == undefined ? "history.callid" : list_data.order_by_name;
		list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
		list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
		list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
		return list_data;
  }
  
  customSearchFilter(enquiry_search){
    this.clicktype='custom';
    this.getEnquiryList({'search_text':enquiry_search});

  }

  // GetAgentbyDepartment(){
  //   let api_req:any = new Object();
  //   let agents_req:any = new Object();
  //   agents_req.action="get_hasemail_department";
  //   agents_req.admin_id=this.admin_id;
  //   api_req.operation="ticket";
  //   api_req.moduleType="ticket";
  //   api_req.api_type="web";
  //   api_req.access_token=localStorage.getItem('access_token');
  //   api_req.element_data = agents_req;    
  //       this.serverService.sendServer(api_req).subscribe((response:any) => {         
  //         if(response.result.status==true){
  //           this.Queue_options = response.result.data;
  //         }
  //       }, 
  //       (error)=>{
  //           console.log(error);
  //       });
  // }
  getEnquiryDropdown(){
    // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"list_enquiry_dropdown"}}
  
    let api_req:any = new Object();
    let dropdown_req:any = new Object();
      api_req.operation="ticket";
      api_req.moduleType="ticket";
      api_req.api_type="web";
      api_req.access_token=localStorage.getItem('access_token');
      dropdown_req.action = "list_enquiry_dropdown";
  
      api_req.element_data=dropdown_req;     
  
      this.serverService.sendServer(api_req).subscribe((response: any) => {
  
        console.log(response);
        this.enquiryTypeList = response.data;
  
      },
      (error) => {
        console.log(error);
      });
  
  
  }

  

  getEnquiryList(data){

    // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"list_enquiry_tickets","admin_id":"64","limit":"10","offset":"0"}}

    var list_data= this.listDataInfo(data);
    let api_req:any = new Object();
    let enquiry_req:any = new Object();
    if(this.clicktype == 'custom'){
      enquiry_req.action="enquiry_ticket_filter";  
      enquiry_req.from_dt=this.fromdate;
      enquiry_req.to_dt=this.todate;
      enquiry_req.dept_id= this.selectedDept;
      enquiry_req.enquiry_dropdown_id= this.selectedStatus;
    }else{
      enquiry_req.action="list_enquiry_tickets";
    }
    enquiry_req.admin_id=localStorage.getItem('admin_id');
    enquiry_req.limit=list_data.limit;
    enquiry_req.offset=list_data.offset;
    enquiry_req.search_text=list_data.search_text;
    api_req.operation="ticket";
    api_req.moduleType="ticket";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data = enquiry_req;

    this.serverService.sendServer(api_req).subscribe((response: any) => {

      if(response.status == "true"){
        
        console.log(response);
        this.enquiry_list = response.data;
        this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.offset, 'total':response.total, 'page_limit' :this.pageLimit });
        this.recordNotFound = this.enquiry_list.length == 0 ? true : false;
      } else {
        this.recordNotFound = true;
      }
    }, 
    (error)=>{
        console.log(error);
    });


  }

  viewMyThread(ticket_id) {
		ticket_id = btoa(ticket_id);

		this.router.navigate(['/ticket-view-thread'], { queryParams: { ticket_id: ticket_id,page_type:'viewfromreport' } });

  }
  



  graphForOutCome(){

    // {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"enquiry_report_piechart","admin_id":"64"}}
    let api_req:any = new Object();
    let chart_req:any = new Object();

    api_req.operation="ticket";
    api_req.moduleType="ticket";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    chart_req.action="enquiry_report_piechart";
    chart_req.admin_id=this.admin_id;
    api_req.element_data = chart_req;

    this.serverService.sendServer(api_req).subscribe((response: any) => {
      
      // this.chartdata = response.data;

      this.chartdata = [  
            ['Name1', 45.0],  
            ['Name2', 36.8],  
            ['Name3', 42.8],  
            ['Name4', 18.5],  
            ['Name5', 16.2]  
          ];  
       let testing = Object.values(this.chartdata);
       console.log(testing);

    }, 
    (error)=>{
        console.log(error);
    });

  }


}
